export default [
  {
    header: 'Users',
    resource: 'managers' || 'leads' || 'brokers' || 'agents',
    action: 'read',
  },

  {
    title: 'Managers',
    route: 'managers',
    icon: 'UsersIcon',
    resource: 'managers',
    action: 'read',
  },
  {
    title: 'Sales Advisors',
    route: 'agents',
    icon: 'UsersIcon',
    resource: 'agents',
    action: 'read',
  },
  {
    title: 'Brokers',
    route: 'brokers',
    icon: 'UsersIcon',
    resource: 'brokers',
    action: 'read',
  },
  {
    title: 'Leads',
    icon: 'UsersIcon',
    children: [
      {
        title: 'All Leads',
        route: 'leads',
        resource: 'leads',
        action: 'read',
      },
      {
        title: 'Meeting Calendar',
        route: 'meetingCalendar',
        resource: 'leads',
        action: 'read',
      },
    ],
  },
]
