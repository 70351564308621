export default [
  {
    header: 'Commission',
    resource: 'commission' || 'create_commission',
    action: 'read',
  },

  /*
  *
  *  Menu Items
  * */
  {
    title: 'Create Commission',
    route: 'create_commission',
    icon: 'FilePlusIcon',
    resource: 'create_commission',
    action: 'read',
  },

  {
    title: 'All Commissions',
    route: 'all_commissions',
    icon: 'DollarSignIcon',
    resource: 'commission',
    action: 'read',
  },
]
