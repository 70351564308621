export default [
  {
    header: 'Settings',
    resource: 'addAdminUsers' || 'adminUsers',
    action: 'read',
  },

  /*
  *
  *  cms Menu Items
  * */
  {
    title: 'Admin users',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Add User',
        route: 'addAdminUser',
        resource: 'addAdminUsers',
        action: 'read',
      },
      {
        title: 'All Users',
        route: 'adminUsers',
        resource: 'adminUsers',
        action: 'read',
      },
    ],
  },
]
